import { useState } from 'react';

const MemberForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const member = { firstName, lastName, email }

    const response = await fetch('http://localhost:4000/api/members', {
      method: 'POST',
      body: JSON.stringify(member),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json();

    if(!response.ok) {
      setError(json.error);
    }
    if(response.ok) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setError(null);
      console.log('New member added', json)
    }
  }

  return (
    <div className="memberForm">
      <form className="create" onSubmit={handleSubmit}>
        <h3>Become a Member</h3>

        <label>First Name: </label>
        <input
          type="text"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
        /><br />

        <label>Last Name: </label>
        <input
          type="text"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
        /><br />

        <label>Email: </label>
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />

        <button>Add Member</button>
        {error && <div className="error">{error}</div>}

      </form>
    </div>
  )
}


export default MemberForm;