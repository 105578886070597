import { BrowserRouter, Routes, Route } from "react-router-dom";

// pages & components
// import Navbar from "./components/Navbar";
import MainStage from "./pages/MainStage.js";
import Layout from "./pages/Layout.js";
import Contact from "./pages/Contact";
import Members from "./pages/Members";
import NoPage from "./pages/NoPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Navbar /> */}
        <div className="pages">
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<MainStage />} />
              <Route path="Members" element={<Members />} />
              <Route path="Contact" element={<Contact />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
