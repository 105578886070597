import BCA_Img from "../assets/img/BCA_Enter_Preview.png";
import CUR_Img from "../assets/img/Cooking_Up_Romance_Enter_Preview.png";
import RP_Img from "../assets/img/Rostrum_Populus_Enter_Preview.png";
import HomeBacksplash from "../assets/img/maenkind_main_logo_distressed_w_seeHearSpeak_and_registered_trademark.png";
import BCA_Video from "../assets/vid/BCA_Video.mp4";
import RPArticle from "../components/RPArticle";

const stageData = [
  {
    id: 0,
    title: "Home",
    previewImgSrc: null,
    previewText: "",
    content: <img src={HomeBacksplash} className="home-backsplash-img" />,
  },
  {
    id: 1,
    title: "Black Club Anonymous",
    previewImgSrc: BCA_Img,
    previewText: "Preview for the video stream",
    content: (
      <video src={BCA_Video} autoPlay height="100%" width="100%" loop>
        Sorry, your browser doesn't support embedded videos
      </video>
    ),
  },
  {
    id: 2,
    title: "Rostrum Populus",
    previewImgSrc: RP_Img,
    previewText: "Preview for the newsletter",
    content: <RPArticle />,
  },
  {
    id: 3,
    title: "Cooking Up Romance",
    previewImgSrc: CUR_Img,
    previewText: "Show coming soon...",
    content: (
      <div className="stage-promo">
        Cooking Up Romance is coming this Fall 2024...
      </div>
    ),
  },
  {
    id: 4,
    title: "About Us",
    previewImgSrc: null,
    previewText: (
      <div className="home-about">
        <p>
          Maenkind is an online platform that provides digital stages for the
          promotion and exhibition of sports, entertainment, and technological
          events. We bring our users a digital world where they can explore
          their passions and safely commune with others who hold common
          interests. Our goal is to be the bridge that brings people together to
          see, to hear, and to speak to one another online or in-person.
        </p>
        <p>In short, “We Promote Humanity!”</p>
      </div>
    ),
    content: (
      <div className="stage-promo">
        More details about Maenkind are coming soon...
      </div>
    ),
  },
];

export default stageData;
