import ChillWaxCandlesVid from "../assets/vid/chill_wax_candles.mp4";

const advertisementData = [
  {
    id: 0,
    title: "Chill Wax Candle",
    content: (
      <video src={ChillWaxCandlesVid} autoPlay height="100%">
        Sorry, your browser doesn't support embedded videos
      </video>
    ),
  },
  {
    id: 1,
    title: "Advertisement Two",
    content: <div>The 2nd advertisement coming soon...</div>,
  },
  {
    id: 2,
    title: "Advertisement Two",
    content: <div>The 3rd advertisement coming soon...</div>,
  },
];

export default advertisementData;
