import { motion } from "framer-motion";

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
};

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const Sidebar = ({ onSelect, stageList }) => {
  const stageListNoHome = stageList.slice(1);
  return (
    <motion.div
      className="sidebar-main"
      initial="closed"
      animate="open"
      exit="closed"
      variants={variants}
    >
      {stageListNoHome.map(({ id, title }) => (
        <motion.div
          key={id}
          variants={itemVariants}
          whileHover={{ scale: 1.1, x: 10 }}
          whileTap={{ scale: 0.95 }}
        >
          <button
            onClick={() => {
              onSelect(id);
            }}
          >
            {title}
          </button>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default Sidebar;
