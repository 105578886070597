import { AnimatePresence, motion, useCycle } from "framer-motion";
import { useState, useEffect } from "react";
import CloseButton from "../components/CloseButton.js";
import Sidebar from "../components/Sidebar.js";
import StagePreview from "../components/StagePreview.js";
import stageData from "../lib/stageData.js";
import advertisementData from "../lib/advertisementData.js";
import { MenuToggle } from "../components/MenuToggle.js";
import CenterStage from "../components/CenterStage.js";

const sidebarBtnVariants = {
  open: {
    clipPath: "circle(40px at 40px 40px)",
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  },
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const panelVariants = {
  offscreen: {
    width: 0,
    opacity: 0,
    transition: {
      duration: 0.5,
      opacity: { duration: 0.2 },
    },
  },
  onscreen: {
    opacity: 1,
    width: 500,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1.5,
    },
  },
};

const MainStage = () => {
  const [activeStageIndex, setActiveStageIndex] = useState(0);
  const [previewStageIndex, setPreviewStageIndex] = useState(0);
  const [advertisementIndex, setAdvertisementIndex] = useState(0);
  const [isAdvertisementOpen, setShowAd] = useState(false);
  const [isPreviewOpen, setShowPreview] = useState(false);
  const [isOpen, toggleOpen] = useCycle(false, true);

  const ADVERTISEMENT_DELAY_SECONDS = 90;

  const toggleAdvertisement = async () => {
    setShowAd(!isAdvertisementOpen);
    await new Promise((resolve) => setTimeout(resolve, 400));
    if (isAdvertisementOpen) {
      setAdvertisementIndex(
        (prevState) => (prevState + 1) % advertisementData.length
      );
    }
  };

  let intervalID;
  useEffect(() => {
    if (intervalID) {
      clearInterval(intervalID);
    }
    if (activeStageIndex === 0) {
      intervalID = setInterval(
        toggleAdvertisement,
        ADVERTISEMENT_DELAY_SECONDS * 1000
      );
    } else {
      setShowAd(false);
    }

    return () => {
      clearInterval(intervalID);
    };
  }, [activeStageIndex, isAdvertisementOpen]);

  const handleSelectPreview = async (id) => {
    if (isPreviewOpen) {
      setShowPreview(false);
      await new Promise((resolve) => setTimeout(resolve, 400));
    }
    if (id !== previewStageIndex && id !== activeStageIndex) {
      setShowPreview(true);
      setPreviewStageIndex(id);
    } else {
      setPreviewStageIndex(0);
    }
  };
  const handleSelectStage = (id) => {
    setShowPreview(false);
    setPreviewStageIndex(0);
    setActiveStageIndex(id);
  };
  const handleClose = () => setShowPreview(false);
  const handleCloseStage = () => {
    setActiveStageIndex(0);
    setShowAd(false);
  };
  const content = isAdvertisementOpen
    ? advertisementData[advertisementIndex].content
    : stageData[activeStageIndex].content;
  const centerStageContentKey = isAdvertisementOpen
    ? `${advertisementData[advertisementIndex].title}-${advertisementData[advertisementIndex].id}`
    : stageData[activeStageIndex].id;
  const isCloseStageBtnDisplayed =
    activeStageIndex !== 0 || isAdvertisementOpen;

  return (
    <>
      <motion.div initial={false} animate={isOpen ? "open" : "closed"}>
        <motion.div
          className="sidebar-background"
          variants={sidebarBtnVariants}
        />
        <MenuToggle toggle={() => toggleOpen()} />
      </motion.div>
      <div className="stage-background">
        <AnimatePresence>
          {isOpen && (
            <motion.aside
              initial={{ width: 0 }}
              animate={{
                minWidth: 200,
                width: 250,
              }}
              exit={{
                width: 0,
                transition: { delay: 0.5, duration: 0.3 },
              }}
              className="sidebar-container"
            >
              <Sidebar
                stageList={stageData}
                onSelect={(id) => handleSelectPreview(id)}
              />
            </motion.aside>
          )}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          <motion.div
            key={centerStageContentKey}
            initial={{ y: 180, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -180, opacity: 0 }}
            transition={{ duration: 1.2 }}
            className="stage-backsplash"
          >
            <CenterStage content={content} />
          </motion.div>
        </AnimatePresence>
        {isCloseStageBtnDisplayed && (
          <div className="main-close-btn-container">
            <CloseButton onClose={handleCloseStage} />
          </div>
        )}
        <AnimatePresence mode="wait">
          {isPreviewOpen && (
            <motion.aside
              key={isPreviewOpen ? stageData[activeStageIndex].title : "empty"}
              initial="offscreen"
              animate="onscreen"
              exit="offscreen"
              viewport={{ once: false, amount: 0.8 }}
            >
              <motion.div
                className="preview-container"
                variants={panelVariants}
              >
                <StagePreview
                  stage={stageData[previewStageIndex]}
                  onSelect={(id) => handleSelectStage(id)}
                />
                <CloseButton onClose={handleClose} />
              </motion.div>
            </motion.aside>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default MainStage;
