const StagePreview = ({
  stage: { id, title, previewText, previewImgSrc },
  onSelect,
}) => {
  const content = previewImgSrc ? (
    <img className="preview-img" src={previewImgSrc} />
  ) : (
    <>
      <h1>{title}</h1>
      {previewText}
    </>
  );
  return (
    <div className="stage-preview" onClick={() => onSelect(id)}>
      {id !== 0 && content}
    </div>
  );
};

export default StagePreview;
