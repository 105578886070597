import { useState, useEffect } from 'react';
import MemberForm from '../components/MemberForm.js';

const Members = () => {

  const [members, setMembers] = useState(null);

  useEffect(() => {
    const fetchWorkouts = async () => {
      const response = await fetch('http://localhost:4000/api/members');
      const json = await response.json();

      if(response.ok) {
        setMembers(json.members);
      }
    }

    fetchWorkouts();
  }, [])


  console.log(members);


  return (
    <div>
      <h1>Members</h1>

        {members && members.map((member) => (
          <p key={member._id}>
           {member.firstName} {member.lastName}
          </p>
        ))}
        <MemberForm />
    </div>
  )
}

export default Members;